import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

import { CustomListColor } from '@/shared/enums/colors';
import { get as readFromLocalStorage, set as writeToLocalStorage } from '@/store/localStorage';

export const STORAGE_KEY_DATA = 'vehicle.list.data';
export const STORAGE_KEY_TAGS = 'vehicle.list.tags';
export const STORAGE_KEY_UNIFIED_COLOR = 'vehicle.list.unifiedColor';
export const STORAGE_KEY_COLORS = 'vehicle.list.colors';

const AVAILABLE_DATA = ['logo', 'weight', 'length', 'volume'];
const AVAILABLE_TAGS = ['appointment', 'details'];

export const useVehicleListSettings = defineStore('vehicle-list-settings', () => {
  const data = ref<string[]>(readFromLocalStorage(STORAGE_KEY_DATA, []));
  const tags = ref<string[]>(readFromLocalStorage(STORAGE_KEY_TAGS, []));
  const colors = ref<Record<string, CustomListColor>>(
    readFromLocalStorage(STORAGE_KEY_COLORS, {
      availabilityDate: 'list-gray',
      goodType: 'list-gray',
      truckTypes: 'list-gray',
      weight: 'list-gray',
      length: 'list-gray',
      volume: 'list-gray',
    })
  );
  const unifiedColor = ref<boolean>(readFromLocalStorage(STORAGE_KEY_UNIFIED_COLOR, true));

  watch(
    unifiedColor,
    () => {
      writeToLocalStorage(STORAGE_KEY_UNIFIED_COLOR, unifiedColor.value);
    },
    { deep: true }
  );

  watch(
    data,
    () => {
      writeToLocalStorage(STORAGE_KEY_DATA, data.value);
    },
    { deep: true }
  );

  watch(
    tags,
    () => {
      writeToLocalStorage(STORAGE_KEY_TAGS, tags.value);
    },
    { deep: true }
  );

  watch(
    colors,
    () => {
      writeToLocalStorage(STORAGE_KEY_COLORS, colors.value);
    },
    { deep: true }
  );

  return {
    data: computed({
      get: () => {
        return AVAILABLE_DATA.filter((value) => !data.value.includes(value));
      },
      set: (values) => {
        data.value = AVAILABLE_DATA.filter((value) => !values.includes(value));
      },
    }),
    tags: computed({
      get: () => {
        return AVAILABLE_TAGS.filter((value) => !tags.value.includes(value));
      },
      set: (values) => {
        tags.value = AVAILABLE_TAGS.filter((value) => !values.includes(value));
      },
    }),
    colors,
    unifiedColor,

    setDefaultColors: (category: 'appointment' | 'details') => {
      if (category === 'appointment') {
        colors.value.availabilityDate = 'list-gray';
      } else if (category === 'details') {
        colors.value.goodType = 'list-gray';
        colors.value.truckTypes = 'list-gray';
        colors.value.weight = 'list-gray';
        colors.value.length = 'list-gray';
        colors.value.volume = 'list-gray';
      }
    },

    setPredefinedColors: (category: 'details') => {
      if (category === 'details') {
        colors.value.goodType = 'list-blue';
        colors.value.truckTypes = 'list-green';
        colors.value.weight = 'list-brown';
        colors.value.length = 'list-purple';
        colors.value.volume = 'list-dark-red';
      }
    },
  };
});
