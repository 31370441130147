import { AbortOptions, Collection, PaginationOptions, SortingOptions } from '@/shared/dataProviders/common';
import {
  B2PWebCollection,
  B2PWebError,
  handleB2PWebResponse,
  mapToCollection,
} from '@/shared/dataProviders/common/b2pweb';
import { RestClient } from '@/shared/dataProviders/common/RestClient';
import {
  FetchThirdPartyVehiclesFilters,
  ThirdPartyVehicle,
  ThirdPartyVehicleDataProvider,
  ThirdPartyVehicleForList,
} from '@/shared/dataProviders/vehicle';
import {
  B2PWebThirdPartyVehicle,
  B2PWebThirdPartyVehicleForList,
  mapToThirdPartyVehicle,
  mapToThirdPartyVehicleForList,
} from '@/shared/dataProviders/vehicle/b2pweb';

export class B2PWebThirdPartyVehicleDataProvider implements ThirdPartyVehicleDataProvider {
  constructor(private readonly client: RestClient) {}

  async count(filters?: FetchThirdPartyVehiclesFilters, options?: AbortOptions): Promise<number> {
    const response = handleB2PWebResponse(
      await this.client.get<{ count: number }, B2PWebError>('/search/vehicles/count', {
        params: {
          cityOrZone: filters?.location,
          availabilityDate: filters?.availabilityDate?.startOf('day').toISO({ suppressMilliseconds: true }),
          hazardousMaterials: filters?.hazardousMaterials,
          goodType: filters?.goodType ? Number(filters.goodType) : undefined,
          truckTypes: filters?.truckTypes ? filters.truckTypes.map((truckType) => Number(truckType)) : undefined,
          weight: filters?.weight,
          length: filters?.length,
          volume: filters?.volume,
          fromCountry: filters?.departureCountry,
          fromZones: filters?.departureZones ? [filters?.departureZones] : undefined,
          fromCoords: filters?.departureCoords,
          toCountry: filters?.arrivalCountry,
          toZones: filters?.arrivalZones ? [filters?.arrivalZones] : undefined,
          toCoords: filters?.arrivalCoords,
          exchanges: filters?.exchanges ? filters.exchanges : undefined,
        },
        signal: options?.signal,
      })
    );
    return response.count;
  }

  async fetchAll(
    filters?: FetchThirdPartyVehiclesFilters,
    options?: AbortOptions & PaginationOptions & SortingOptions
  ): Promise<Collection<ThirdPartyVehicleForList>> {
    const items = handleB2PWebResponse(
      await this.client.get<B2PWebCollection<B2PWebThirdPartyVehicleForList>, B2PWebError>('/search/vehicles', {
        params: {
          savedSearchId: filters?.searchId,
          cityOrZone: filters?.location,
          availabilityDate: filters?.availabilityDate?.startOf('day').toISO({ suppressMilliseconds: true }),
          hazardousMaterials: filters?.hazardousMaterials,
          goodType: filters?.goodType ? Number(filters.goodType) : undefined,
          truckTypes: filters?.truckTypes ? filters.truckTypes.map((truckType) => Number(truckType)) : undefined,
          weight: filters?.weight,
          length: filters?.length,
          volume: filters?.volume,
          fromCountry: filters?.departureCountry,
          fromZones: filters?.departureZones ? [filters?.departureZones] : undefined,
          fromCoords: filters?.departureCoords,
          toCountry: filters?.arrivalCountry,
          toZones: filters?.arrivalZones ? [filters?.arrivalZones] : undefined,
          toCoords: filters?.arrivalCoords,
          exchanges: filters?.exchanges ? filters.exchanges : undefined,
          page: options?.page,
          per_page: options?.perPage,
          sort: options?.sort,
        },
        signal: options?.signal,
      })
    );
    return mapToCollection(items, mapToThirdPartyVehicleForList);
  }

  async fetch(id: string, options?: AbortOptions): Promise<ThirdPartyVehicle> {
    const item = handleB2PWebResponse(
      await this.client.get<B2PWebThirdPartyVehicle, B2PWebError>(`/search/vehicles/${id}`, {
        signal: options?.signal,
      })
    );
    return mapToThirdPartyVehicle(item);
  }
}
