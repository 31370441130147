export const keys = {
  auth: 'app.auth',
  locale: 'app.locale',
  theme: 'app.theme',
  themeColorScheme: 'app.theme.colorScheme',
  isMultiWindowingActive: 'grid.isMultiWindowingActive',
  gridAsideMenuCollapsed: 'grid.asideMenuCollapsed',
  preferredLayoutIds: 'grid.preferredLayoutIds',
  activeSearchIds: 'grid.activeSearchIds',
  searchAsideMenuCollapsed: 'search.asideMenuCollapsed',
  activeSearchId: 'search.activeSearchId',
  searchColors: 'search.colors',
  searchShortNames: 'search.shortNames',
  searchPanelsForceLeft: 'search.panels.forceLeft',
  searchPanelsForceRight: 'search.panels.forceRight',
  offerListUserIdFilter: 'offer.offerListUserIdFilter',
  routingMapOptions: 'map.routingMapOptions',
  notificationRequirementsRead: 'notification.requirements.read',
  notificationSettingsDisabledSearches: 'notification.settings.disabledSearches',
};

export function get<T>(key: string, defaultValue: T): T {
  if (localStorage.getItem(key) === null) {
    return defaultValue;
  }

  return JSON.parse(localStorage.getItem(key) || '""');
}

export function set(key: string, value: unknown) {
  if (value === undefined) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
