import { AbortOptions } from '@/shared/api/cartography/types';
import { cancellable } from '@/shared/api/util/cancellable';

import client from './axios';

export interface Request {
  search: string;
  country: string;
}

export interface City {
  name: string;
  originalName: string;
  zipcode: string;
  countyCode: string; // zone
  county: string;
  country: string;
  latitude: number;
  longitude: number;
}

export interface Collection<T> {
  items: T[];
  page: number;
  pageCount: number;
  limit: number;
  totalCount: number;
}

export async function getCities(request: Request, options?: AbortOptions): Promise<Collection<City>> {
  const { data } = await cancellable(
    client.get<Collection<City>>('cities', {
      params: request,
      signal: options?.signal,
    })
  );

  return data;
}
