import { DateTime } from 'luxon';

import { OptimizationType } from '@/shared/api/cartography';
import { Delivery, Lading, OfferExactLocation, State } from '@/shared/api/offer/types';
import { CustomerContact, ForbiddingLevel, Price } from '@/shared/api/search';
import { Contact } from '@/shared/api/search/offer/contact';
import { ExactLocation } from '@/shared/api/search/offer/exact-location';
import { Location } from '@/shared/api/search/offer/location';
import { Country } from '@/shared/enums/country';
import { GoodType } from '@/shared/enums/goodType';
import { LoadingDateType } from '@/shared/enums/loadingDateType';
import { Sound } from '@/shared/enums/sound';
import { TruckType } from '@/shared/enums/truckType';
import { VehicleProfile } from '@/shared/enums/vehicleProfile';
import { TranslatedMessage } from '@/shared/locale';

export type { ConsultedSearchOfferListFilters, Currency, Price } from '@/shared/api/search';
export { ForbiddingLevel } from '@/shared/api/search';
export type { ExactLocation } from '@/shared/api/search/offer/exact-location';
export type { Location } from '@/shared/api/search/offer/location';

export interface Search {
  id: string;
  color: string;
  shortName: string;
  name: string;
  dateStart: DateTime | null;
  dateEnd: DateTime | null;
  loadingDateType: LoadingDateType | null;
  fromZones: string[];
  fromCountry: Country;
  toZones: string[];
  toCountry: Country;
  goodType: GoodType;
  truckType: TruckType;
  minWeight: number | null;
  maxWeight: number | null;
  minLength: number | null;
  maxLength: number | null;
  maxVolume: number | null;
  hazardousMaterials: boolean | null;
  exchanges: string[];
  sound: Sound | null;
  enabled: boolean;
  sendByEmail: boolean;
}

export interface SearchInput {
  color: string;
  shortName: string;
  name?: string;
  dateStart?: DateTime;
  dateEnd?: DateTime;
  loadingDateType?: LoadingDateType;
  fromCountry?: Country;
  fromZones?: string[];
  toCountry?: Country;
  toZones?: string[];
  goodType?: string;
  truckType?: string;
  minWeight?: number;
  maxWeight?: number;
  minLength?: number;
  maxLength?: number;
  maxVolume?: number;
  hazardousMaterials?: boolean | null;
  sendByEmail?: boolean;
  exchanges: string[];
  sound?: Sound;
  saveAsTemplate?: boolean;
}

export interface SearchOffer {
  id: string;
  customer: CustomerContact;
  forbiddingLevel: ForbiddingLevel;
  goodType: GoodType;
  truckTypes: TruckType[];
  lading: Location;
  delivery: Location;
  hazardousMaterials: boolean;
  weight: number | null;
  length: number | null;
  volume: number | null;
  distance: number | null;
  exchangeType: number;
  isConsulted: boolean;
  isRecent?: boolean;
  updatedAt: DateTime;
}

export interface SearchOfferDetail extends SearchOffer {
  contact: Contact | null;
  notes: string | null;
  price: Price | null;
  orderRef: string | null;
  ladingExactLocation: ExactLocation | null;
  deliveryExactLocation: ExactLocation | null;
}

export interface ConsultedSearchOffer {
  id: string;
  customer: CustomerContact;
  goodType: GoodType;
  truckTypes: TruckType[];
  lading: Location;
  delivery: Location;
  ladingExactLocation: ExactLocation | null;
  deliveryExactLocation: ExactLocation | null;
  hazardousMaterials: boolean;
  weight: number | null;
  length: number | null;
  volume: number | null;
  distance: number | null;
  consultedAt: DateTime;
  contact: Contact | null;
  notes: string | null;
  price: Price | null;
  orderRef: string | null;
  isBookmarked: boolean;
  userNotes: string | null;
  userPrice: Price | null;
}

export enum PostPartnerToProfileSwitch {
  Never = -1,
  FiveMinutes = 5,
  TenMinutes = 10,
  FifteenMinutes = 15,
  ThirtyMinutes = 30,
  OneHour = 60,
  TwoHours = 120,
  ThreeHours = 180,
}

export interface OfferInput {
  partnerExchanges?: string[];
  partnerToProfileSwitch?: PostPartnerToProfileSwitch;
  lading: OfferLadingInput;
  delivery: OfferDeliveryInput;
  ladingExactLocationNotFound: boolean;
  ladingExactLocation?: OfferExactLocationInput;
  deliveryExactLocationNotFound: boolean;
  deliveryExactLocation?: OfferExactLocationInput;
  goodType: GoodType;
  truckTypes?: TruckType[];
  hazardousMaterials: boolean;
  weight: number;
  length: number;
  volume?: number;
  notes?: string;
  orderRef?: string;
  price?: number;
  partnerPrice?: number;
  contact: {
    id: string;
    civility?: string;
    name?: string;
    email?: string;
    fax?: string;
    phone?: string;
    mobile?: string;
    languages?: string[];
  };
  differedAt?: DateTime;
  profileId?: string;
  saveAsTemplate?: boolean;
}

export interface OfferLadingInput {
  city: string;
  zipCode: string;
  zone: string;
  country: string;
  latitude: number;
  longitude: number;
  dateStart: DateTime;
  dateEnd?: DateTime;
}

export interface OfferDeliveryInput {
  city: string;
  zipCode: string;
  zone: string;
  country: string;
  latitude: number;
  longitude: number;
  dateStart?: DateTime;
  dateEnd?: DateTime;
}

export interface OfferExactLocationInput {
  isPublic?: boolean;
  houseNumber?: string;
  street?: string;
  city?: string;
  zipCode?: string;
  zone?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
}

export interface OfferCore {
  id: string;
  userId: string;
  partnerExchanges: string[];
  partnerToProfileSwitch: PostPartnerToProfileSwitch | null;
  price: Price;
  partnerPrice: Price;
  contact: OfferContact;
  customerContact: OfferCustomerContact;
  goodType: GoodType;
  truckTypes: TruckType[];
  lading: Lading;
  delivery: Delivery;
  ladingExactLocation?: OfferExactLocation;
  deliveryExactLocation?: OfferExactLocation;
  hazardousMaterials: boolean;
  weight: number;
  length: number;
  volume: number | null;
  distance: number | null;
  notes: string;
  orderRef: string;
  profileId: string;
  lastActionDate: DateTime;
}

interface OfferContact {
  id: string;
  civility: string | null;
  name: string;
  phone: string | null;
  fax: string | null;
  mobile: string | null;
  email: string | null;
  languages: string[];
}

interface OfferCustomerContact {
  id: string;
  name: string;
  country: string;
  zone: string;
  logo: string | null;
}

export interface SketchyOffer extends OfferCore {
  hasError: boolean;
}

export interface Offer extends SketchyOffer {
  states: State[]; // TODO Check usage
}

export interface DeletedOffer extends OfferCore {
  deleteCause: string | null;
}

export interface OfferAction {
  type: OfferActionType;
  exchanges: OfferActionExchange[];
  creationDate: DateTime;
}

export enum OfferActionType {
  Post = 'post',
  Delete = 'delete',
  AutoDelete = 'auto_delete',
  Update = 'update',
  Refresh = 'refresh',
  Unknown = 'unknown',
}

export interface OfferActionExchange {
  name: string;
  shortname: string;
  status: OfferActionExchangeStatus;
}

export enum OfferActionExchangeStatus {
  Pending = 'pending',
  Success = 'success',
  Failure = 'failure',
}

export type { OfferListFilters } from '@/shared/api/offer/read';
export type { Consultation } from '@/shared/api/offer/types';
export type { StatisticInput } from '@/shared/api/offer/write';

export type OfferTemplate = Omit<OfferCore, 'lastActionDate'>;

export interface SelectOption<T = string> {
  text: string;
  value: T;
}

export type Scale = 'sm' | 'md' | 'lg' | 'xl';
export type Layout = 'row' | 'column';

export class AppError extends Error {
  constructor(message: string | TranslatedMessage) {
    super(message as string);
  }
}

export interface RoutingMapOptions {
  avoidTolls: boolean;
  optimization: OptimizationType;
  vehicleProfile: VehicleProfile;
}
