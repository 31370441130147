import { KeycloakError } from 'keycloak-js';
import { defineStore } from 'pinia';
import { computed, ComputedRef, Ref, ref, watch } from 'vue';

import { acl, getPermissions } from '@/services/acl';
import keycloak from '@/services/keycloak';
import { loadTimeSettings } from '@/shared/clock';
import config from '@/shared/config';
import { parseLocale } from '@/shared/enums/locale';
import Timer from '@/shared/timer';
import window from '@/shared/window';
import { get as localStorageGet, keys, set as localStorageSet } from '@/store/localStorage';

export interface Auth {
  accessToken: string;
  id: string;
  customerId: string;
  email: string;
  name: string;
  roles: string[];
}

function composeAuth(): Auth {
  return {
    accessToken: keycloak.token ?? '',
    id: keycloak.idTokenParsed?.user_id ?? '',
    customerId: keycloak.idTokenParsed?.company_id ?? '',
    email: keycloak.idTokenParsed?.email ?? '',
    name: keycloak.idTokenParsed?.name ?? '',
    roles: keycloak.idTokenParsed?.roles ?? [],
  };
}

function isKeycloakError(error: unknown): error is KeycloakError {
  return error !== null && typeof error === 'object' && 'error' in error && 'error_description' in error;
}

export class AccessDeniedError extends Error {}
export class ServiceUnavailableError extends Error {}

const minTokenValidityInSeconds = config.auth.tokenValidityDuration;
const tokenRefreshRetryDelayInSeconds = config.auth.tokenRefreshRetryDelayInSeconds;

// Timer used to refresh the token
const updateTokenTimer = new Timer();

export interface UseAppStoreReturn {
  auth: Ref<Auth | null>;
  isLoggedIn: ComputedRef<boolean>;
  locale: Ref<string>;

  login(): Promise<void>;
  logout(): Promise<void>;
  updateToken(force?: boolean): Promise<void>;
  loadUserPermissions(): Promise<void>;
  setLocale(newLocale: string): void;
}

export const useAppStore = defineStore('app', (): UseAppStoreReturn => {
  const auth = ref<Auth | null>(null);
  const locale = ref(localStorageGet<string>(keys.locale, parseLocale(navigator.language)));

  const isLoggedIn = computed(() => auth.value !== null);

  watch(locale, () => {
    localStorageSet(keys.locale, locale.value);
    window.location.reload();
  });

  keycloak.onReady = (authenticated) => {
    if (authenticated && keycloak.idTokenParsed?.locale && !localStorageGet(keys.locale, '')) {
      locale.value = parseLocale(keycloak.idTokenParsed.locale);
    }
  };

  keycloak.onAuthLogout = async () => {
    await logout();
  };

  // On refresh error, we reschedule an update of the token if the refresh token is still valid. Otherwise, we log out the user
  keycloak.onAuthRefreshError = async () => {
    if (!canRefreshToken()) {
      await logout();

      return;
    }

    updateTokenTimer.start(() => updateToken(), tokenRefreshRetryDelayInSeconds * 1000);
  };

  // On token expiration, we update the token if the refresh token is still valid. Otherwise, we log out the user
  keycloak.onTokenExpired = async () => {
    if (!canRefreshToken()) {
      await logout();

      return;
    }

    await updateToken(true);
  };

  async function login(): Promise<void> {
    if (!keycloak.authenticated) {
      const isAuthenticated = await new Promise((resolve, reject) => {
        const timeoutID = setTimeout(() => reject(new ServiceUnavailableError()), config.auth.authServerTimeout * 1000);

        keycloak
          .init({
            onLoad: 'check-sso',
            pkceMethod: 'S256',
            silentCheckSsoRedirectUri: config.auth.silentCheckRedirectUri,
          })
          .then(resolve)
          .catch((e) => {
            if (isKeycloakError(e) && e.error === 'access_denied') {
              e = new AccessDeniedError(e.error_description);
            }

            reject(e);
          })
          .finally(() => clearTimeout(timeoutID));
      });

      if (!isAuthenticated) {
        await keycloak.login({ locale: locale.value });
      }
    }

    auth.value = composeAuth();

    await loadTimeSettings();
    await loadUserPermissions();

    // We try to refresh the token if its minimum validity period is not respected
    await updateToken();
  }

  async function updateToken(force = false) {
    updateTokenTimer.clear();

    const isRefreshed = await keycloak.updateToken(force ? -1 : minTokenValidityInSeconds);

    if (isRefreshed) {
      auth.value = composeAuth();

      await loadUserPermissions();
    }

    if (keycloak.tokenParsed?.exp) {
      const timeToUpdateInSeconds = getTimeToExpiryInSeconds(keycloak.tokenParsed.exp) - minTokenValidityInSeconds;

      if (timeToUpdateInSeconds < 0) {
        return;
      }

      updateTokenTimer.start(() => updateToken(true), timeToUpdateInSeconds * 1000);
    }
  }

  async function logout(): Promise<void> {
    updateTokenTimer.clear();

    auth.value = null;
    acl.setPermissions([]);

    await keycloak.logout();
  }

  function canRefreshToken(): boolean {
    return (
      keycloak.refreshTokenParsed !== undefined &&
      keycloak.refreshTokenParsed.exp !== undefined &&
      getTimeToExpiryInSeconds(keycloak.refreshTokenParsed.exp) > 0
    );
  }

  function getTimeToExpiryInSeconds(expirationDateInSeconds: number) {
    return expirationDateInSeconds - new Date().getTime() / 1000 + (keycloak.timeSkew ?? 0);
  }

  async function loadUserPermissions(): Promise<void> {
    if (auth.value) {
      acl.setPermissions(await getPermissions(auth.value));
    }
  }

  function setLocale(newLocale: string): void {
    locale.value = newLocale;
  }

  return {
    auth,
    locale,
    isLoggedIn,

    login,
    logout,
    updateToken,
    loadUserPermissions,
    setLocale,
  };
});
