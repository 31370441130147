import { uniqueId } from 'lodash-es';
import { computed, ComputedRef, inject, onBeforeUnmount, provide, Ref, ref, watch } from 'vue';

export type Position = 'left' | 'right';

interface FloatingPanelProvider {
  container: Ref;
  register: (id: string) => void;
  unregister: (id: string) => void;
}

const key = Symbol();

export function useFloatingPanels() {
  const container = ref();
  const panels = ref(new Set<string>());

  const hasFloatingPanel = computed(() => panels.value.size > 0);

  provide<FloatingPanelProvider>(key, {
    container,

    register: (id: string) => {
      panels.value.add(id);
    },

    unregister: (id: string) => {
      panels.value.delete(id);
    },
  });

  return { container, hasFloatingPanel };
}

export function useFloatingPanel(position: Position, shouldBeFloating: ComputedRef<boolean>) {
  const { container, register, unregister } = inject<FloatingPanelProvider>(key, {
    container: ref(),

    register: () => {
      // Nothing
    },

    unregister: () => {
      // Nothing
    },
  });

  const isFloating = computed(() => container.value && shouldBeFloating.value);

  const id = uniqueId();

  watch(
    isFloating,
    () => {
      if (isFloating.value) {
        register(id);
      } else {
        unregister(id);
      }
    },
    { immediate: true }
  );

  onBeforeUnmount(() => {
    unregister(id);
  });

  return { container, isFloating };
}
