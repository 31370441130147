import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

import { useMediaQuery } from '@/shared/composables/useMediaQuery';
import { ColorScheme, getCurrentTheme, Theme } from '@/shared/theme';
import { get as localStorageGet, keys, set as localStorageSet } from '@/store/localStorage';

export const useThemeStore = defineStore('theme', () => {
  const colorScheme = ref(localStorageGet<ColorScheme | undefined>(keys.themeColorScheme, undefined));

  const isColorSchemeDarkPreferred = useMediaQuery('(prefers-color-scheme: dark)');
  const computedColorScheme = computed(
    () => colorScheme.value ?? (isColorSchemeDarkPreferred.value ? ColorScheme.Dark : ColorScheme.Light)
  );

  const current = ref(localStorageGet<Theme>(keys.theme, getCurrentTheme(computedColorScheme.value)));

  const isPrivate = computed(() => {
    return current.value !== Theme.B2PWeb && current.value !== Theme.DarkB2PWeb;
  });

  watch(current, (current) => {
    localStorageSet(keys.theme, current);
  });

  watch(colorScheme, (colorScheme) => {
    localStorageSet(keys.themeColorScheme, colorScheme);
  });

  watch(computedColorScheme, (colorScheme) => {
    if (current.value === Theme.B2PWeb || current.value === Theme.DarkB2PWeb) {
      current.value = colorScheme === ColorScheme.Dark ? Theme.DarkB2PWeb : Theme.B2PWeb;
    }
  });

  function switchTo(theme: Theme) {
    if (theme === Theme.B2PWeb || theme === Theme.DarkB2PWeb) {
      current.value = computedColorScheme.value === ColorScheme.Dark ? Theme.DarkB2PWeb : Theme.B2PWeb;
    } else {
      current.value = theme;
    }
  }

  return {
    colorScheme,

    current,
    isPrivate,

    switchTo,
  };
});
