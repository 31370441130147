import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

import { getAvailableLayouts, GridLayout } from '@/shared/grid';
import { Search } from '@/shared/types';
import { get as localStorageGet, keys, set as localStorageSet } from '@/store/localStorage';

const maxActiveSearches = 6;

export const useSearchGridStore = defineStore('search-grid', () => {
  const isMultiWindowingActive = ref(localStorageGet<boolean>(keys.isMultiWindowingActive, false));
  const isAsideMenuCollapsed = ref(localStorageGet<boolean>(keys.gridAsideMenuCollapsed, true));
  const activeSearchIds = ref(localStorageGet<string[]>(keys.activeSearchIds, []));
  const preferredLayouts = ref(
    localStorageGet<string[]>(keys.preferredLayoutIds, [
      getAvailableLayouts(0)[0].id,
      getAvailableLayouts(1)[0].id,
      getAvailableLayouts(2)[0].id,
      getAvailableLayouts(3)[0].id,
      getAvailableLayouts(4)[0].id,
      getAvailableLayouts(5)[0].id,
      getAvailableLayouts(6)[0].id,
    ])
  );

  const draggedSearchId = ref<string>();

  function setDraggedSearchId(id?: string) {
    draggedSearchId.value = id;
  }

  function getGridLayout(nbItems: number): GridLayout {
    nbItems = Math.min(nbItems, maxActiveSearches);

    let layout: GridLayout | undefined;
    const layoutId: string | undefined = preferredLayouts.value[nbItems];

    if (layoutId) {
      layout = getAvailableLayouts(nbItems).find((layout: GridLayout) => layout.id === layoutId);
    }

    return layout || getAvailableLayouts(nbItems)[0];
  }

  function setPreferredLayout(layout: GridLayout) {
    preferredLayouts.value.splice(layout.items.length, 1, layout.id);
  }

  function onSearchesUpdated(payload: { searches: Search[] }) {
    if (!payload.searches.length) {
      set([]);
      return;
    }

    const filteredSearchIds = activeSearchIds.value.filter(
      (searchId) => payload.searches.findIndex(({ id }) => id === searchId) > -1
    );

    if (filteredSearchIds.length) {
      set(filteredSearchIds);
    } else {
      set([payload.searches[0].id]);
    }
  }

  function onSearchCreated(payload: { search: Search }) {
    if (activeSearchIds.value.length < 2) {
      set([payload.search.id]);
    }
  }

  function onSearchUpdated(payload: { previousId: Search['id']; newId: Search['id'] }) {
    if (activeSearchIds.value.length > 1) {
      replace({ id: payload.previousId, newId: payload.newId });
    } else {
      set([payload.newId]);
    }
  }

  function onSearchDeleted(payload: { searchId: Search['id'] }) {
    remove(payload.searchId);
  }

  function set(searchIds: string[]) {
    activeSearchIds.value = searchIds.slice(0, maxActiveSearches);
  }

  function addAtIndex(index: number, id: string) {
    if (index >= maxActiveSearches) {
      return;
    }

    activeSearchIds.value[index] = id;
  }

  function canAdd() {
    return activeSearchIds.value.length < maxActiveSearches;
  }

  function add(id: string) {
    if (!canAdd()) {
      return;
    }

    activeSearchIds.value = [...activeSearchIds.value, id];
  }

  function replace({ id, newId }: { id: string; newId: string }) {
    activeSearchIds.value = activeSearchIds.value.map((searchId) => {
      if (searchId === id) {
        return newId;
      } else if (searchId === newId) {
        return id;
      }

      return searchId;
    });
  }

  function remove(id: string) {
    activeSearchIds.value = activeSearchIds.value.filter((searchId) => searchId !== id);
  }

  watch(isMultiWindowingActive, () => {
    localStorageSet(keys.isMultiWindowingActive, isMultiWindowingActive.value);
  });

  watch(isAsideMenuCollapsed, () => {
    localStorageSet(keys.gridAsideMenuCollapsed, isAsideMenuCollapsed.value);
  });

  watch(
    preferredLayouts,
    () => {
      localStorageSet(keys.preferredLayoutIds, preferredLayouts.value);
    },
    { deep: true }
  );

  watch(
    activeSearchIds,
    () => {
      localStorageSet(keys.activeSearchIds, activeSearchIds.value);
    },
    { deep: true }
  );

  return {
    isMultiWindowingActive,
    isAsideMenuCollapsed,

    getGridLayout,
    preferredLayouts,
    setPreferredLayout,

    activeSearchIds,
    canAdd,
    add,
    addAtIndex,
    remove,
    replace,
    set,

    onSearchesUpdated,
    onSearchCreated,
    onSearchUpdated,
    onSearchDeleted,

    draggedSearchId,
    setDraggedSearchId,
  };
});
